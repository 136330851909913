.interface {
    -webkit-transition-duration: .75s;
    transition-duration: .75s;
    position: relative;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-wrap: nowrap;
    max-width: 1500px;
    min-height: 100%;
    overflow: hidden;
    margin: 0 auto;
    -webkit-box-shadow: 0 5px 50px 0 rgb(0 0 0 / 40%);
    box-shadow: 0 5px 50px 0 rgb(0 0 0 / 40%);
    border-radius: 15px;
}

.wrapper {
    height: calc(100% - 6rem);

}
#root {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    z-index: 1;
    position: fixed;
}

.noline-button {
    outline: none !important;
}

.h-100 {
    height: 100px !important;
}

.dropdown-menu {
    display: flex;
    flex-direction: column;
    z-index: 10000;
}

.no-outline button {
    outline: none !important;
}

.page-z {
    z-index: 1000;
}

.searchbar-input {
    background-color: transparent;
    outline: none !important;
    color: #c2c2c2;
}