.interface {
    -webkit-transition-duration: .75s;
    transition-duration: .75s;
    position: relative;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-wrap: nowrap;
    max-width: 1500px;
    min-height: 90vh !important;
    overflow: hidden;
    margin: 0 auto;
}

#root {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    z-index: 1;
    position: fixed;
}


.noline-button {
    outline: none !important;
}

.h-100 {
    height: 100px !important;
}

.dropdown-menu {
    display: flex;
    flex-direction: column;
    z-index: 10000;
}

.no-outline button {
    outline: none !important;
}

.page-z {
    z-index: 1000;
}

.searchbar-input {
    background-color: transparent;
    outline: none !important;
    color: #c2c2c2;
}

.rstm-toggle-icon {
    width: 25px;
    height: 25px;
    display: flex;
    position: absolute;
    left: -25px;
    justify-items: center;
}

.rstm-tree-item {
    display: flex;
    width: 100%;
    align-items: center;
}

.rstm-tree-item-group {
    position: relative;
}

.rstm-tree-item-level0 {
    padding-left: 0px !important;;
}

.rstm-tree-item-level1 {
    padding-left: 0px !important;
    width: 100% !important;
}

.rstm-div-node {
    width: 100%;
}

.action-buffer-space {
    width: calc(3.75rem - 25px);
}

.action-header-buffer-space {
    width: calc(3rem);
}