@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(../assets/fonts/poppins/Poppins-Regular.ttf) format("truetype");
}

body {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 0px;
}

p {
  font-family: 'Poppins';
}

html, body {
  height: 100%;
  width: 100%;
  background-attachment: fixed;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

#body {
  flex-direction: column;
  overflow-x: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 200;
}

*, *::before, *::after {
  box-sizing: inherit;
}



