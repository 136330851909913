.SubHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  letter-spacing: 0.1px;
  font-size: 10px;
  min-width: 992px;
  width: 100%;
  //max-width: 1366px;
  margin: 0 auto;
  margin-right: 20px;
  margin-left: 20px;

  /* Remove header responsiveness from Core UI */
  .navbar-brand {
    position: initial;
    top: initial;
    left: initial;
    margin-left: initial;
  }
  .d-md-down-none {
    display: inherit !important;
  }
  .nav-items{
    justify-content: space-between;
  }
  .nav-item .nav-link{
    text-align: left;
  }
  .px-3{
    padding-left: 0 !important;
    min-width: auto;
  }
  .nav-link.active{
    font-weight: bolder;
  }
   
}
